@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --cart-page-divider-background: var(--secondary-base-color);
    --cart-page-promo-background: #fff;
    --cart-page-table-head-background: var(--secondary-base-color);
    --desktop-loader-offset: calc(0px - var(--header-total-height) - var(--breadcrumbs-height) - 32px);
}

.CartPage {
    margin-block-end: var(--footer-total-height);

    @include mobile {
        --footer-totals-height: 123px;
        --header-mobile-height: 80px;

        margin-block-start: 0;
        padding-block-end: var(--footer-totals-height);
        padding-inline: 16px;

        .CheckoutOrderSummary-ButtonWrapper {
            @include mobile {
                padding: 14px 16px 14px 16px
            }

            position: relative;
            width: 100%;
            background: transparent;
            padding: 16px;

            &.Sticky {
                position: fixed;
                inset-block-end: 0;
                z-index: 10;
                background: var(--color-white);
                box-shadow: 0px 4px 20px 8px rgba(0, 0, 0, 0.35);
                border-radius: 16px 16px 0 0;
            }
        }
    }

    &-CheckoutButtonWrapper {
        @include desktop {
            padding-inline: 24px;
            margin-block-start: 14px;
        }


        .CartPage {
            &-CheckoutButton {
                width: 100%;
                text-align: center;
                background: var(--primary-base-color) 0% 0% no-repeat padding-box;
                opacity: 1;
                border: none;
                font-weight: 500;
                border-radius: 8px;
                column-gap: 16px;
        
                &[disabled] {
                    background: #9F9D9C;
                    font-size: 16px;
                    color: #fff;
                    opacity: 1;
                }
        
                &:hover {
                    border-radius: 8px;
                }
        
                @include mobile {
                    font-size: 16px;
                }
            }
        }
    }

    &-Items {
        display: grid;
        row-gap: 2rem;
    }

    &-EmptyCms {
        margin: 3rem 0;
    }

    &-EmptyInner {
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        flex-direction: column;

        @include desktop {
            margin-block-start: 10rem;
        }

        .EmptyImage {
            width: 131px;
        }

        .CartPage-Heading {
            display: none;
        }

        .EmptyHeading {
            margin-block-start: 35px;
            margin-block-end: 16px;
        }

        .Content {
            margin-block-end: 34px;
        }

        .ShoppingButton {
            button {
                background-color: var(--primary-base-color);
                padding: 16px 30px;
                cursor: pointer;
                color: #fff;
                font-size: 14px;
                font-weight: 500;
            }
        }

        @include mobile {
            padding: 2rem 14px;
            text-align: center;
        }
    }

    &-SeedslabelClone {
        background-color: #545CA0;
        border-radius: 24px;
        padding: 2px 8px 2px 8px;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        color: #fff;
        display: flex;
        width: auto;
        height: 24px;
        margin-inline: 16px;

        @include mobile {
            width: auto;
            height: 24px;
        }
    }

    &-SeedslabelSeeds {
        background-color: #A0546F;
        border-radius: 24px;
        padding: 2px 8px 2px 8px;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        color: #fff;
        margin-inline-start: 16px;
        display: flex;
        width: auto;
        height: 24px;

        @include mobile {
            width: auto;
            height: 24px;
        }
    }

    &-SeedslabelWrapper {
        border-left: 1px solid #EEEEEE;
    }

    &-LabelWrapper {
        display: flex;
        align-items: center;
    }

    .DiscountMsg {
        &-Wrapper {
            padding: 16px 0px;
        }

        &-Message {
            background: #D6EBCE;
            font-size: 14px;
            font-weight: 600;
            text-align: center;
            padding: 4px 0;
            border-radius: 8px;
            margin-block-end: 0px;
        }

        &-WrapperNotFound {
            padding: 8px 0;
            background: #EBCFCF;
            border-radius: 8px;
            text-align: center;
            margin: 3rem 0 0;
        }

        &-MessageNotFound {
            font-size: 14px;
            font-weight: 600;
            padding: 0;
            margin-block-end: 0px;
        }
    }

    .CmsBlock-Wrapper {
        max-width: var(--content-wrapper-width);
        margin: 0 auto 2rem;
    }

    &-Wrapper {
        @include desktop {
            display: grid;
            grid-template-columns: calc(100% - 446px) 416px;
            grid-column-gap: 3rem;
            max-width: 1200px;
            padding-inline: unset;
            margin-block: 3rem;
        }

        @include tablet {
            grid-column-gap: 4%;
        }

        @include mobile {
            padding: 0;
        }

        &.isEmpty {
            grid-template-columns: 1fr;
        }

        > div:not(.CartPage-Floating):nth-child(3) {
            grid-column: 1/3;
        }
    }

    &-Initial,
    &-Items {
        &LoaderContainer {
            inset-inline-start: 0;
            inset-block-start: 0;
            z-index: 10;

            .Loader {
                margin: none;

                &-Scale {
                    @include mobile {
                        position: fixed;
                        inset-block-start: auto;
                        inset-block-end: 50%;
                        transform: translate(-50%, 50%);
                    }
                }
            }
        }
    }

    &-SummaryWrapper {
        background: #F9F6F4;
        border-radius: 8px;
    }

    &-InitialLoaderContainer {
        position: fixed;

        @include desktop {
            max-width: 100vw;
            max-height: 100vh;
            width: 100vw;
            height: 100vh;
        }
    }

    &-ItemsLoaderContainer {
        position: absolute;
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
    }

    &-Summary {
        @include mobile {
            inset-inline-start: 0;
            width: 100%;
        }

        .ApplyRewards {
            display: none;
        }

        .CheckoutOrderSummary {
            &-Header {
                display: block;
            }

            &-viewBasketButton {
                display: none;
            }
        }
    }

    &-CheckoutButtons {
        padding: 12px 0;

        @include mobile {
            padding: 14px;
        }
    }

    &-CloneWarningWrapper {
        display: flex;
        background-color: #EBCF88;
        padding: 8px;
        border-radius: 8px;
        margin-block-start: 20px;
        margin-block-end: 30px;
    }

    &-CloneWarningWrapperParent {
        display: flex;
        background-color: #EBCF88;
        padding: 4px;
        border-radius: 8px;
        margin: 16px 24px 8px 24px;
    } 

    &-CloneWarningMessage {
        line-height: 20px;
        font-weight: 400;
        font-size: 12px;
        color: #000000;
        text-align: center;
    }

    &-CloneWarning {
        line-height: 24px;
        font-weight: 600;
        font-size: 16px;
        color: #000000;
        padding-inline-start: 8px;
    }

    &-OutOfStockProductsWarning {
        background-color: #fff4f4;
        padding-inline-start: 24px;
        padding-inline-end: 24px;
        padding-block-start: 16px;
        font-size: 14px;
        color: var(--primary-error-color, #eb0202);

        @include mobile {
            padding-inline-start: 0px;
            padding-inline-end: 0px;
            padding-block-start: 0px;
            font-size: 14px;
        }
    }

    &-FreebieWarning {
        padding: 0 24px;
        margin: 14px 0 0 0;

        @include mobile {
            padding: 0;
            margin: 0 0 14px 0;
        }

        .Message {
            @include mobile {
                margin-block-start: 16px;
                margin-block-end: 16px;
            }

            background: #EBCF88;
            border-radius: 8px;
            text-align: center;
            font-size: 12px;
            padding: 6px 0;
        }
    }

    &-Total {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        align-items: center;
        font-weight: bold;
        padding: 12px 0;
        min-height: 48px;

        @include mobile {
            padding-block-start: 14px;
            padding-block-end: 0;
            padding-inline: 14px;
            min-height: 0;
        }

        dd {
            text-align: end;

            span {
                display: block;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }

    &-TableHead {
        display: grid;
        grid-template-columns: 3fr 2fr 1fr;
        background-color: var(--cart-page-table-head-background);
        text-transform: capitalize;
        font-size: 14px;
        font-weight: 600;
        padding: 14px 16px;
        margin-block-end: 0;
        border-radius: var(--input-border-radius);

        @include mobile {
            display: none;
        }

        span {
            text-align: start;

            &:last-of-type {
                text-align: end;
            }
        }
    }

    & &-Discount {
        border-block-start: 0;

        @include mobile {
            border-block-start: 1px solid var(--expandable-content-divider-color);
            border-block-end: 0;
        }

        @include desktop {
            margin-block-start: 32px;
        }
    }

    &-Floating {
        width: 100%;

        .ExpandableContent {
            @include mobile {
                border-top: none;
                border: none;
            }
        }

        @include mobile {
            margin-block-start: 16px;
        }
        
        @include desktop {
            display: flex;
            flex-direction: column;
            margin: 5.5rem auto 0;
            border-radius: 3px;
            opacity: 1;
        }

        @include tablet {
            margin-block-start: 15px;
        }

        .ApplyRewards {
            @include mobile {
                padding: 16px;
                margin-block: 0;
                border: none;
            }
        }
    }

    &-Heading {
        margin-block: unset;
        font-size: 20px;
        padding-inline-end: 16px;

        @include mobile {
            font-size: 16px;
        }
    }

    &-ExpandableContentContent {
        margin-block-start: 0;
    }

    .ExpandableContent {
        &.CartPage-Coupon {
            padding: 0 24px;

            @include mobile {
                padding: 0 16px;
            }

            .ExpandableContent {
                &-Button {
                    justify-content: center;
                    border-top: 1px solid #C7C5C3;
                    padding: 24px 0;

                    @include mobile {
                        padding: 24px 0;
                    }
    
                    svg {
                        display: none;
                    }
                }

                &-Heading {
                    font-size: 16px;
                    font-weight: 500;
                    text-decoration: underline;
                }

                &-Content {
                    &_isContentExpanded {
                        padding-block-end: 24px;
                    }
                }
            }
        }
    }

    .ProductLinks-Wrapper {
        padding-inline: 0;

        @include desktop {
            padding-inline: 32px;
        }
    }

    .ProductLinks-List {
        grid-column-gap: 16px;
        z-index: 10;

        .ProductCard-ProductActions {
            display: flex;
        }

        .AddToCart {
            width: 100%;
            margin-inline-end: 0;
        }
    }

    .CheckoutOrderSummary {
        @include desktop {
            padding: 0;
            margin-block-start: 0;
        }

        &-ItemList {
            display: none;
        }
        
        .Field [type="checkbox"] + .input-control {
            ::after {
                --checkmark-color: #ffffff;

                transition-duration: unset;
                transition-property: unset;
                transition-timing-function: unset;
            }
        }

        .Field [type="checkbox"]:hover:not(:disabled) + .input-control {
            --box-color: #000000;

            border-color: var(--box-color);
        }

        .Field [type="checkbox"]:checked + .input-control::after {
            --checkmark-color: #ffffff;
        }

        .Field [type="checkbox"]:checked + .input-control {
            border-color: unset;
        }

        .Field-CheckboxLabel:not([class*="_isDisabled"]):hover {
            color: #000000;
        }

        @include mobile {
            &-OrderTotalExclusiveTax {
                display: flex;
                order: 7;
                margin-block-start: 14px;
                padding-block-start: 24px;
                padding-block-end: 24px;
            }

            &-SummaryItem_isTotal {
                display: none;
            }

            &-SafetyConcern {
                order: 9;
                row-gap: 5px;
            }
        }
    }

    &-Head_Content {
        display: flex;
        align-items: center;
        column-gap: 6px;
        flex-direction: row;
        justify-content: flex-start;

        @include mobile { 
            span {
                font-size: 12px;
            }
        }
    }

    &-item {
        background: var(--primary-base-color);
        border-radius: 50%;
        color: var(--color-white);
        height: 22px;
        padding: 2px;
        min-width: 22px;
        display: flex;
        font-size: 13px;
        justify-content: center;
        align-items: center;
        font-weight: 600;
    }

    &-free {
        color: var(--primary-base-color);
        font-size: 14px;
        font-weight: 500;

        @include mobile {
            font-size: 11px;
        }
    }

    &-Inner {
        margin-block-end: 1.6rem;

        @include mobile {
            row-gap: 1rem;
        }

        p {
            margin-block-end: unset;
        }

        button {
            color: #000000;
            text-transform: uppercase;
            opacity: 1;
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #000000;
            padding: 1.5rem;
            font-size: 14px;
            font-weight: bold;
            cursor: pointer;

            &:hover {
                color: var(--hollow-button-hover-color);
                border-color: var(--hollow-button-hover-color);
            }

            @include mobile {
                font-size: 11px;
            }
        }
    }

    &-OrderTotal {
        font-size: 14px;
        font-weight: 500;
        background-color: #fff4f4;
        padding: 1.5rem;
        margin: 2rem 0;
        display: flex;
        gap: 10px;
        color: var(--primary-error-color, #eb0202);
    }

    .Button_isHollow:not([disabled]):hover {
        border-radius: 16px;
    }

    .FreebieChoice {
        &-Wrapper {
            border-top: 1px solid var(--primary-divider-color);
            padding-block-start: 2rem;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include mobile {
                flex-direction: column;
                row-gap: 1rem;
                align-items: flex-start;
            }
        }

        &-Content {
            .Prompt {
                font-size: 14px;
                font-weight: 600;
                margin-block-end: 6px;

                @include mobile {
                    margin-block-end: 6px;
                }
            }

            .label {
                font-size: 14px;
                color: #3E3D30;

                @include mobile {
                    background-color: transparent;
                }
            }
        }

        &-Button {
            button {
                font-weight: 600;
                border-color: var(--color-black);
                color: var(--color-black);
                border-radius: 16px;
            }
        }
    }

    &-FreebiesWrapper {
        display: grid;
        row-gap: 16px;
        background-color: #EAF5E7;
        padding: 16px;
        border-radius: 16px;
        margin-block: 2rem;
    }

    &-Freebies {
        background-color: #73A054;
        border-radius: 8px;

        span {
            font-size: 16px;
            font-weight: 600;

            @include mobile {
                font-size: 16px;
            }
        }

        .CartPage {
            &-Headingss {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 18px;
                font-weight: 600;
                line-height: 26px;
                color: var(--color-white);
                padding: 11px 18px;

                @include mobile {
                    font-size: 12px;
                }

                .Title {
                    display: flex;
                    align-items: center;
                    column-gap: 1rem;
                }

                .FreebieEdit {
                    color: var(--color-white);
                    font-size: 14px;
                    text-transform: uppercase;
                    font-weight: 600;
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &-FreebiesList {
        padding-block-end: 2rem;
    }

    &-Highlight {
        color: var(--primary-base-color);
        font-size: 14px;
        font-weight: 600;
        margin-inline: 0.5rem;
    }

    &-FreebiesListWrapper {
        display: flex;
        column-gap: 1rem;
        overflow-x: auto;
        overflow-y: hidden;

        .FreebiesCard {
            &-Wrapper {
                max-width: 200px;
                min-width: 160px;
                width: 100%;
                overflow: hidden;
                border-radius: 8px;

                @include mobile {
                    max-width: 160px;
                }
            }

            &-ImgBlock {
                margin-block-end: -4px;

                .Image {
                    -webkit-padding-after: 100%;
                    padding-block-end: 100%;

                    &_imageStatus_image_loading,
                    &_isPlaceholder,
                    &_imageStatus_image_loaded {
                        background-image: var(--placeholder-image);
                        background-size: var(--placeholder-size);
                        animation: var(--placeholder-animation);
                    }
                }
            }

            &-Details {
                background: var(--color-white);
                padding: 14px;
            }

            &-Headings {
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                margin-block-end: 5px;
                font-size: 14px;
                font-weight: 600;
            }

            &-Content {
                .Product-BrandName, a {
                    display: inline-block;
                    font-size: 12px;
                    font-weight: 400;
                    margin-block-end: 9px;
                    color: #3E3D30;
                }
            }
        }

        .swiper {
            padding: 0 2rem 3rem 0;

            @include mobile {
                padding: 0 0 2rem 0;
            }

            .swiper-wrapper {
                padding-block-end: 0;
                margin-block-end: 1rem;

                .swiper-slide {
                    /* stylelint-disable-next-line declaration-no-important */
                    width: fit-content !important;
                }
            }

            .swiper-button-next, 
            .swiper-button-prev {
                inset-block-start: 95%;
                height: 10px;
                width: 10px;
            }
        }
    }

    &-Details {
        display: grid;
        justify-items: end;
        margin-block-start: 2%;

        @include mobile {
            justify-items: unset;
        }

        .CartPage {
            &-AddButton {
                align-self: end;
            }
        }
    }

    &-ProductName {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        row-gap: 5rem;

        .CartPage {
            &-Headings {
                font-size: 16px;
                text-transform: capitalize;
                opacity: 1;
                line-height: 2rem;
            }
        }

        @include mobile {
            row-gap: 0;
            justify-content: space-between;
        }
    }

    &-AddButton {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        font-weight: 600;

        button {
            width: 58px;
            height: 32px;
            border: 2px solid var(--color-black);
            border-radius: 8px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                color: var(--hollow-button-hover-color);
                border-color: var(--hollow-button-hover-color);
            }
        }
    }

    &-Owner {
        color: #9b9b9b;
        text-transform: capitalize;
        opacity: 1;
    }

    &-Inner_Content {
        display: flex;
        flex-wrap: wrap;
        gap: 3%;
        row-gap: 2px;
        font-size: 14px;

        span {
            font-size: 14px;
            color: #9B9B9B;
        }

        h4 {
            color: var(--primary-base-color);
        }

        @include mobile {
            .CartPage {
                &-AddButton {
                    width: 100%;
                }
            }
        }
    }

    &-Itemss {
        display: flex;
        column-gap: 4px;
        margin-block-end: 5px;
        font-size: 12px;
        line-height: 20px;
        color: #3E3D30;
    }

    &-DownMsg {
        margin-block-start: 2%;
        width: 50%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        @include mobile {
            width: 100%;
            justify-content: center;
            gap: 4rem;
            margin: 2rem 0;
            padding-block-end: 2rem;
            border-bottom: 1px solid var(--primary-divider-color);
        }

        span:nth-child(1) {
            font-size: 14px;
            color: #757575;

            @include mobile {
                font-size: 11px;
            }
        }

        span {
            img {
                width: 40px;
            }
        }
    }
}

body:has(.CartPage) {
    footer {
        @include mobile {
            padding-block-end: 50px;
        }
    }
}
