@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/parts';

/**
 * Amasty Extra Fee compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.CartAdditionalFees {
    &-Option {
        display: flex;
        justify-content: space-between;
        padding: 0;
        text-transform: capitalize;
        font-size: 16px;
        padding-block-start: 14px;

        @include mobile {
            font-size: 16px;
            padding-block-start: 14px;
        }

        .price {
            text-align: end;
            font-weight: 600;
        }
    }
}
