@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --cart-overlay-totals-background: var(--secondary-base-color);
    --cart-overlay-divider-background: #d8d8d8;
    --cart-overlay-promo-background: #fff;
}

.CartOverlay {
    cursor: auto;
    box-shadow: 0px 3px 9px #00000029;
    position: fixed;
    width: 100%;
    z-index: 9999;
    inset-inline-start: 0;
    inset-block-start: 0;
    height: 100%;
    overflow: auto;

    &:has(.DiscountMsg-Wrapper) {
        .CartOverlay-Discount {
            padding-block-end: 0px;
        }
    }

    @include mobile {
        z-index: 999;
        inset-block-start: 0;
    }

    &::before {
        @include desktop {
            content: "";
            position: absolute;
            inset-block-start: -8px;
            inset-inline-end: 9px;
            height: 15px;
            width: 15px;
            transform: rotate(45deg);
            background-color: var(--primary-base-color);
            border-block-start: 1px solid var(--primary-base-color);
            border-inline-start: 1px solid var(--primary-base-color);
        }
    }

    &-SeedslabelClone {
        background-color: #545CA0;
        border-radius: 24px;
        padding: 2px 8px 2px 8px;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        color: #fff;
        display: flex;

        @include mobile {
            width: auto;
            height: 24px;
            margin-inline-start: 12px;
        }
    }

    &-SeedslabelSeeds {
        background-color: #A0546F;
        border-radius: 24px;
        padding: 2px 8px 2px 8px;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        color: #fff;
        display: flex;
        width: auto;
        height: 24px;

        @include mobile {
            width: auto;
            height: 24px;
        }
    }

    &-SeedslabelWrapper {
        border-left: 1px solid #EEEEEE;
        padding: 8px;
    }

    &-CartOverlayContentBackground {
        width: calc(100vw - 2px);
        height: 100vh;
        position: absolute;
        z-index: 100;
        background: #00000060 0% 0% no-repeat padding-box;

        @include desktop {
            width: 100%;
        }
    }

    &-CartOverlayContentWrapper {
        background: #ffffff;
        width: 535px;
        height: 100vh;
        inset-inline-end: 0;
        position: absolute;
        z-index: 105;
        overflow-y: hidden;
        transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

        @include mobile {
            width: 91%;
        }
    }

    .label {
        flex-basis: 50%;
        white-space: break-spaces;
    }

    .price {
        flex-basis: 30%;
        text-align: end;
    }

    .MiniCart-container {
        // position: fixed;
        height: calc(100vh - 42px);

        @include mobile {
            position: fixed;
            width: 85%;
        }

        h2.Cart-Heading {
            padding: 15px;
            font-size: 25px;
            font-weight: 500;

            @include mobile {
                font-size: 14px;
                margin: 0;
                border-bottom: 1px solid var(--color-gray);
            }
        }
    }

    .Cart-Header {   
        display: flex;
        align-items: center;

        .Cart-Heading {
            padding: 15px;
            font-size: 25px;
            font-weight: 500;

            @include mobile {
                font-size: 14px;
                margin: 0;
                border-bottom: 1px solid var(--color-gray);
            }
        }

        button {
            position: absolute;
            inset-inline-end: 10px;
            inset-block-start: 1.5rem;
            cursor: pointer;

            svg {
                fill: var(--color-back);
                width: 30px;
                height: 30px;
            }
        }
    }

    .CartOverlay-ContentWrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 100px);
        border: none;
        padding: 10px;

        @include mobile {
            padding: 0;
            height: calc(100vh - 60px);
        }

        .CartOverlay-Additional {
            border-top: 1px solid var(--primary-divider-color);
            background-color: var(--color-white);
            padding: 1rem 0;

            @include mobile {
                padding: 0;
            }

            .CartAdditionalFees {
                &-Option { 
                    padding-block-start: 0px;
                    padding: 12px 16px 6px 16px;
                }

                @include mobile {
                    span {
                        font-size: 12px;
                    }
                }
            }

            .PriceDetails {
                display: flex;
                justify-content: space-between;
                font-size: 16px;
                line-height: 20px;
                word-wrap: break-word;
                padding-block-start: 12px;
                padding-block-end: 6px;
                padding-inline-start: 16px;
                padding-inline-end: 16px;

                @include mobile {
                    font-size: 12px;
                }
            }

            .Discount {
                padding-block-end: 6px;
                padding-inline-start: 16px;
                padding-inline-end: 16px;

                @include mobile {
                    padding-block-start: 0;
                }

                .ProductIi-Content {
                    @include mobile {
                        border: 0;

                        .ProductIi-ExpandableContentButton {
                            padding-block-end: 10px;
                            padding-block-start: 10px;
                        }
                    }

                    .ExpandableContent-Button {
                        &_isContentExpanded {
                            .ProductIi-ExpandableContentHeading {
                                &::after {
                                    content: "";
                                }
                            }
                        }
                    }
                }

                .ProductIi-DiscountApplied {
                    .ExpandableContent-Button {
                        display: none;
                    }
                }

                .ProductIi-ExpandableContentHeading {
                    &::after {
                        content: attr(data-content);
                        inset-inline-end: 33px;
                        font-size: 14px;
                        color: var(--primary-base-color);

                        @include mobile {
                            font-size: 10px;
                        }
                    }
                }

                .ExpandableContent-Heading {
                    cursor: pointer;
                    font-weight: 400;
                    font-size: 16px;
                    position: relative;
                    flex: 100%;
                    display: flex;
                    justify-content: space-between;
                    padding-block-start: 12px;

                    @include mobile {
                        font-size: 12px;
                        display: flex;
                        justify-content: space-between;
                    }
                }

                svg {
                    display: none;
                }
            }

            .CartOverlay-Total {
                background: none;
                font-size: 16px;
                font-weight: 500;

                @include mobile {
                    font-size: 12px;
                }

                dd {
                    font-size: 22px;
                    font-weight: 600;

                    @include mobile {
                        font-size: 15px;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    &-DeliveryInsurance {
        display: block;

        .Fee {
            display: none;
        }

        @include mobile {
            .CartAdditionalFees-Option {
                font-size: 12px;
            }
        }
    }

    .DiscountField {
        .Field {
            &-ErrorMessages {
                padding-block-start: 16px;
            }
        }

        width: 100%;

        .CartCoupon {
            &_remove {
                .Form {
                    flex-direction: row;
                    align-items: center;
                }
            }

            @include mobile {
                padding: 0;
            }

            &-Button {
                height: var(--button-height);

                @include mobile {
                    font-size: 12px;
                    display: block;
                    padding: 1rem;
                    margin: 10px 0;
                    width: fit-content;
                }

                @include smallmobile {
                    font-size: 10px;
                    padding: 0;
                    height: 30px;
                }
            }

            @include mobile {
                &-MessageText {
                    font-size: 12px;
                    margin-inline-end: 10px;
                    white-space: break-spaces;
                }
            }
        }
    }

    .ExpandableContent {
        @include mobile {
            padding-block-end: 0;
        }

        &-Content {
            margin-block-start: unset;
            overflow: visible;
            font-size: 14px;

            @include desktop {
                max-height: 0;
                opacity: 0;
            }

            &_isContentExpanded {
                margin-block-start: 12px;

                @include desktop {
                    max-height: 100%;
                    opacity: 1;
                }
            }
        }
    }

    &-Empty {
        padding: 16px;
        text-align: center;
    }

    &-Actions {
        display: flex;
        padding: 12px 16px 16px 16px;

        @include mobile {
            padding: 14px;
        }
    }

    &-OutOfStockProductsWarning {
        font-size: 14px;
        background-color: #fff4f4;
        padding: 1.5rem;
        margin: 1rem 1.5rem;
        color: var(--primary-error-color, #eb0202);
        white-space: pre-wrap;
    }

    &-CheckoutButton {
        margin-inline-start: 12px;

        @include mobile {
            margin-inline-start: 16px;
            width: 100%;
            display: block;
            text-align: center;
        }

        @include desktop {
            flex-grow: 1;
            text-align: center;
        }
    }

    &-CartButton {
        background-color: var(--primary-base-color);
        border: none;
        color: var(--color-white);
        font-weight: 500;
        width: 100%;

        @include mobile {
            font-size: 12px;
        }

        &.Button:not([disabled]):hover {
            background-color: var(--button-hover-background);
            color: var(--button-hover-color);
        }
    }

    &-Promo,
    &-Total {
        font-weight: 600;

        @include desktop {
            min-height: 36px;
        }
    }

    &-Promo {
        padding: 12px;
        text-align: center;
        background: var(--cart-overlay-promo-background);
        margin-block-end: 0;

        @include mobile {
            padding: 14px;
            border-block-end: 1px solid var(--cart-overlay-divider-background);
            border-block-start: 1px solid var(--expandable-content-color);
        }

        @include desktop {
            padding: 10px;
            color: var(--color-white);
            background: var(--primary-base-color);
        }

        strong {
            margin: 0 5px;
        }
    }

    &-Items {
        padding-inline: 0;
        overflow-y: auto;
        padding-block-end: 20px;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 20px;
            background-color: var(--color-gray);
        }

        @include mobile {
            padding-inline: 0;
        }
    }

    &-Tax {
        font-size: 14px;
    }

    &-Total,
    &-Tax,
    &-Discount {
        word-wrap: break-word;
        display: flex;
        align-items: center;
        font-size: 16px;
        justify-content: space-between;
        padding-block-start: 12px;
        padding-block-end: 6px;
        padding-inline: 16px;

        @include mobile {
            padding-block-end: 6px;
            padding-inline: 16px;
            font-size: 12px;
        }

        dd {
            text-align: end;
            display: flex;
            flex-direction: column;
            white-space: nowrap;

            span {
                display: block;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }

    &-DiscountCoupon {
        word-break: break-word;
    }

    &-Total {
        font-weight: bold;
        font-size: 18px;
    }

    &-ContentWrapper {
        border: 1px solid var(--primary-divider-color);
    }

    .Popup-Content {
        min-height: 100vh;
        margin-inline-start: auto;
        overflow: hidden;
        padding: 10px;
        max-width: 540px;
        width: 100%;

        @include mobile {
            margin-inline-start: 100px;
            inset-block-start: 0;
            z-index: 999;
        }

        .Popup-Header {
            @include mobile {
                display: block;
            }

            .Popup-CloseBtn {
                inset-inline-end: 0;
                inset-block-start: 3.5rem;
                cursor: pointer;

                svg {
                    fill: black;
                }

                &:hover {
                    svg {
                        opacity: 0.8;
                    }
                }

                @include mobile {
                    inset-inline-start: unset;
                    inset-inline-end: 50px;
                    inset-block-start: 10px;
                }
            }
        }
    }

    .DiscountMsg {
        &-Wrapper {
            padding: 2rem 0px 0px 0px;
        }

        &-Message {
            background: #D6EBCE;
            font-size: 14px;
            font-weight: 600;
            text-align: center;
            padding: 4px 0;
            border-radius: 8px;
            margin-block-end: 0px;
        }

        &-WrapperNotFound {
            padding: 8px 0;
            background: #EBCFCF;
            border-radius: 8px;
            text-align: center;
            margin: 2rem 0 0;
        }

        &-MessageNotFound {
            font-size: 14px;
            font-weight: 600;
            padding: 0;
            margin-block-end: 0px;
        }
    }
}

