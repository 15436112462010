@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CartIcon {
    fill: #000000;
    cursor: pointer;

    @include desktop {
        margin-block-start: -10px;
    }

    @include mobile {
        width: 30px;
        height: 30px;
    }
}
