@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.AddToCart {
    width: unset;
    border-radius: 8px;


    &:not([disabled]):hover {
        border-radius: 8px;
    }
    
    @include mobile {
        min-width: 145px;
        padding-block: 0.4rem;
        height: auto;
        white-space: normal;

        span {
            white-space: normal;
        }
    }

    span {
        font-size: 16px;
        font-weight: 600;

        @include mobile {
            font-size: 15px;
            padding-block: 0.4rem;
        }
    }

    @include minimobile {
        min-width: -webkit-fill-available;
        padding-block: 0.4rem;
    }

    &:has(svg) {
        gap: 1rem;
    }
}

